<template>
  <div id="accept-tos">
    <TermsOfServiceModal />
  </div>
</template>

<script>
import TermsOfServiceModal from '@/components/Modal/TermsOfServiceModal'

export default {
  name: 'AcceptTermsOfService',
  created () {
    const loggedUser = this.$store.getters.loggedUser

    if (loggedUser.hasAcceptedTerms) {
      this.$router.push({ name: 'dashboard' })
    }
  },
  components: {
    TermsOfServiceModal
  }
}
</script>
