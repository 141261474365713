<template>
  <b-modal
    id="modal-tos"
    centered
    size="xl"
    v-model="modalShow"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    scrollable
  >
    <template v-slot:modal-title>
      TÉRMINOS Y CONDICIONES GENERALES (AGOSTO 2020)
    </template>
    <div class="text-left">
      <h4> 1. TÉRMINOS Y CONDICIONES PARA TÉCNICOS AUDIOVISUALES DE “AVIFY“</h4>

      <h5>1.1 IDENTIFICACIÓN, ACEPTACIÓN Y ACCESO AL SITIO WEB</h5>
      <p>
        Los presentes Términos y Condiciones (en adelante los “<strong>TyCG</strong>”)  regulan, la descarga, acceso, navegación y uso de
        la aplicación móvil AVIFY y el Sitio Web https://avify.net/ (en adelante, denominadas conjuntamente, la
        “<strong>Plataforma</strong>”) titularidad de AVIFY SOLUTIONS, S.L.U. (en adelante, “<strong>AVIFY</strong>”), con CIF B-40653297 y domicilio
        social en la Calle Juan Ramón Jiménez 7, 46900, Torrent, Valencia, inscrita en el Registro Mercantil de Valencia
        (Hoja V-192284, folio 195, tomo 10808).</p>
      <p>Toda persona que acceda o utilice el Sitio Web asume la condición de usuario, que supone la aceptación de estos Términos y Condiciones, así como de las diferentes modificaciones y/o textos legales adicionales que AVIFY pueda incluir en el futuro. El acceso y utilización de los contenidos y servicios tras la entrada en vigor de sus modificaciones o los cambios en las condiciones suponen la aceptación de las mismas.</p>

      <h5>1.2. SERVICIOS</h5>
      <p>A través de la Plataforma, AVIFY pone a disposición de los usuarios una serie de servicios dirigidos a técnicos audiovisuales (en adelante, el <strong>“Técnico Audiovisual”</strong> o los <strong>“Técnicos Audiovisuales”</strong>) mediante los cuales les ofrece la posibilidad de dar visibilidad sobre su experiencia, talentos y conocimientos profesionales ante empresas del sector audiovisual (en adelante, <strong>“Empresas”</strong>), así como de realizar formaciones relacionadas con el sector audiovisual (en adelante, los <strong>“Servicios“</strong>).</p>

      <h6>1.2.1 Visibilidad ante Empresas: </h6>
      <p>Para poder acceder a los Servicios ofrecidos a través de la Plataforma de AVIFY, los Técnicos Audiovisuales deberán registrarse en la Plataforma y crear un perfil de Usuario. Una vez registrados en la Plataforma, los Técnicos Audiovisuales podrán: </p>
      <p>a) <strong>Completar su perfil de Usuario y obtener visibilidad ante Empresas:</strong> AVIFY ofrece a los Técnicos Audiovisuales la posibilidad de completar su perfil de usuario para dar visibilidad a sus experiencias y conocimientos en el sector del cine, eventos, conciertos, teatro, espectáculos, televisión y medios ante Empresas interesadas en contactar con Técnicos Audiovisuales para la realización de eventos.</p>
      <p>A través de su perfil de usuario, el Técnico Audiovisual podrá completar su perfil con información relativa a: </p>
      <ol>
        <li>Datos de contacto.</li>
        <li>Información que contiene su perfil público.</li>
        <li>Habilidades con las cuales cuente para su efectivo ofrecimiento.</li>
        <li>Perfil profesional.</li>
        <li>Idiomas</li>
        <li>Licencias y certificaciones.</li>
        <li>Área geográfica disponible para prestar el servicio.</li>
        <li>Disponibilidad de fechas.</li>
      </ol>
      <p>Este servicio tendrá carácter totalmente gratuito para los Técnicos   Audiovisuales. Los Servicios de pago, son herramientas adicionales cuya contratación es en todo caso opcional para los Técnicos Audiovisuales.</p>
      <p><strong>b) Enviar su presupuesto a Empresas.</strong></p>
      <p>Cuando una Empresa muestre interés en la contratación de un Técnico Audiovisual para la prestación de sus servicios en algún evento, el Técnico Audiovisual podrá, de manera opcional, enviar su presupuesto a la Empresa a través de la Plataforma.</p>
      <p>El envío de un presupuesto a través de la Plataforma de AVIFY, permitirá a los Técnicos Audiovisuales dejar constancia por escrito de los términos económicos pactados. Una vez celebrado el evento y prestado el servicio presupuestado por el Técnico Audiovisual, se emitirá una factura en función del presupuesto previamente creado a través de la Plataforma.</p>
      <p>La compra de este servicio online podrá llevarse a cabo directamente a través de la Plataforma de acuerdo a lo establecido en la cláusula 1.4 de los presentes TyCG relativas a los pedidos y condiciones de compra de los servicios online.</p>
      <p>En todo caso, la intervención y/o intermediación de AVIFY a través de la Plataforma se limita a la puesta en contacto de Técnicos Audiovisuales y Empresas y no crea ningún contrato de sociedad, de mandato, de franquicia, o relación laboral entre AVIFY y los Usuarios. AVIFY no es parte en ninguna operación de contratación entre los Técnicos Audiovisuales y las Empresas, ni tiene control alguno sobre la calidad, seguridad o legalidad de los servicios requeridos o la veracidad o exactitud de los perfiles creados.</p>

      <h6>1.2. 2 Formaciones a Técnicos Audiovisuales: </h6>
      <p>AVIFY ofrece a los Técnicos Audiovisuales la posibilidad de contratar formaciones relacionadas con sus habilidades y perfil profesional con el objetivo de aportar un valor añadido a sus perfiles de usuario. Los Técnicos Audiovisuales interesados en la contratación de formaciones deberán estar registrados en la Plataforma y contar con un perfil de usuario.</p>
      <p>Las formaciones a Técnicos Audiovisuales serán impartidas de forma online por profesionales expertos en las diferentes áreas de especialización, a través de vídeos a los que los Técnicos Audiovisuales podrán acceder con su cuenta de usuario, y contarán, en su caso, con material de apoyo relacionado no descargable.</p>
      <p>El acceso a las formaciones y material de apoyo estará accesible a los Técnicos Audiovisuales durante el periodo de tiempo por el cual hayan contratado la formación.</p>
      <p>Una vez completada la formación, AVIFY validará su realización en el perfil de usuario del Técnico Audiovisual.</p>
      <p>La compra de formaciones online podrá realizarse directamente a través de la Plataforma y según lo establecido en la cláusula 1.5 de los presentes TyCG relativas a los pedidos y condiciones de compra de formaciones online.</p>

      <h5>1.3 NECESIDAD DE REGISTRO Y ACCESO AL ÁREA DE USUARIO</h5>
      <p>El acceso a los Servicios y gran parte de los contenidos de la Plataforma requerirá el registro de los Técnicos Audiovisuales. El registro en la Plataforma para los Técnicos Audiovisuales tiene carácter gratuito.</p>
      <p>Para efectuar el registro en la Plataforma, el Técnico Audiovisual deberá crearse una cuenta, facilitando su nombre y correo electrónico, y escogiendo una contraseña de acceso a la Plataforma.</p>
      <p>El registro como Usuarios se podrá hacer desde la propia App de AVIFY, así como desde la página web de AVIFY. Los Usuarios garantizan que sus datos serán exactos, actuales y veraces.</p>
      <p>El Técnico Audiovisual podrá registrarse en la Plataforma a través de las siguientes formas:</p>

      <h6>A. Registro a través de Facebook</h6>
      <p>El Técnico Audiovisual podrá registrarse a través de su perfil de Facebook, introduciendo su usuario y contraseña de Facebook y obtener una cuenta en la Plataforma.</p>
      <p>Mediante el registro a través de Facebook, el Usuario autoriza a que AVIFY pueda conocer su perfil público y dirección de correo electrónico.</p>

      <h6>A. Registro a través de Google</h6>
      <p>El Técnico Audiovisual podrá registrarse a través de sus credenciales de Google, autorizando a que AVIFY pueda conocer su perfil público y dirección de correo electrónico.</p>

      <h6>C. Registro a través de AppleId.</h6>
      <p>El Técnico Audiovisual podrá registrarse a través de sus credenciales de Apple Id, autorizando a que AVIFY pueda conocer su perfil público y dirección de correo electrónico.</p>

      <h6>1.3.1 Custodia de códigos o contraseñas de acceso a la cuenta</h6>
      <p>El Técnico Audiovisual registrado será responsable en todo momento de la custodia de su contraseña de acceso a su cuenta de usuario asumiendo en consecuencia cualesquiera daños y perjuicios que pudieran derivarse de su uso indebido, así como de la cesión, revelación o extravío de la misma, debiendo informar inmediatamente a AVIFY en caso de que tenga motivos para creer que su código de acceso o contraseña ha sido utilizado de manera no autorizada o es susceptible de serlo. </p>
      <p>En cualquier caso, el acceso y/o uso de la Plataforma realizado bajo el código de acceso o contraseña del Técnico Audiovisual registrado se reputarán realizados por dicho usuario, quien responderá en todo caso de dicho acceso y uso.</p>

      <h6>1.3.2 Área de Usuario del Técnico Audiovisual</h6>
      <p>El Técnico Audiovisual tendrá acceso desde el primer día a su perfil de usuario donde podrá completar sus datos personales y habilidades conforme a lo establecido en la Plataforma.</p>
      <p>Una vez registrado, el Técnico Audiovisual tendrá acceso a su Área de Usuario desde la que podrá modificar y organizar su perfil siempre que lo desee.</p>
      <p>AVIFY tiene el derecho a solicitar algún comprobante y/o dato adicional a efectos de corroborar los Datos Personales, así como de suspender temporal o definitivamente aquellos Usuarios cuyos datos no han podido ser confirmados. En los casos de inhabilitación, se los dará de baja de la Plataforma, sin que ello genere derecho a resarcimiento alguno.</p>
      <p>AVIFY tiene derecho a rechazar cualquier solicitud de registro o cancelar una registración previamente aceptada, sin que esté obligada a comunicar o exponer las razones de su decisión y sin que ello genere derecho a indemnización y/o resarcimiento alguno.</p>

      <h5>1.4 CONDICIONES DE COMPRA DE LOS SERVICIOS ONLINE</h5>
      <p>Los Técnicos Audiovisuales que deseen contratar los servicios de elaboración de presupuesto descritos en la cláusula 1.2.1.b de los presentes TyCG,     deberán, en su caso, rellenar un formulario en el que tendrán que incluir los datos relativos al método de pago seleccionado. Al final del formulario deberán seleccionar la opción “comprar”.</p>

      <h6>1.4.1 Precios y forma de pago</h6>
      <p>Los precios del servicio online consisten en la contratación de una compra unitaria o bien una suscripción mensual y se encuentran detallados en el Sitio Web. Se expresan en Euros e incluyen el IVA y los demás impuestos que pudieran corresponder.</p>
      <p>Los pagos podrán realizarse mediante PayPal, Stripe o a través de Tarjeta Crédito/Débito. La elección del medio de pago será del Técnico Audiovisual.</p>
      <p>En caso de que el pago se haga efectivo a través del servicio externo titularidad de Paypal, estará sujeto a los Términos y Condiciones y a la Política de Privacidad de Paypal disponible en <a href="www.paypal.com" target="_blank">www.paypal.com.</a></p>
      <p>En caso de que el pago se haga efectivo a través del servicio externo titularidad de Stripe, estará sujeto a los Términos y Condiciones y a la Política de Privacidad de Stripe disponible en <a href="https://stripe.com/es" target="_blank">https://stripe.com/es.</a></p>
      <p>Una vez realizada la compra, y en un plazo máximo de 48 horas, AVIFY se pondrá en contacto con el Usuario para concretar las sesiones.</p>

      <h6>1.4.2 Derecho de desistimiento</h6>
      <p>De conformidad con lo establecido en la legislación vigente, el Técnico Audiovisual dispone de 14 días naturales desde la efectiva contratación de los Servicios para el ejercicio del derecho de desistimiento. El plazo para el ejercicio del derecho de desistimiento se computará desde el ingreso de los datos bancarios del Técnico Audiovisual en el Sitio Web.</p>
      <p>Para ejercer el derecho de desistimiento, el Cliente tendrá que notificar su decisión de desistir del Servicio contratado a través de una declaración inequívoca (por ejemplo, una carta enviada por correo postal, fax o correo electrónico).</p>
      <p>No obstante, no podrá ejercerse el derecho de desistimiento sobre:</p>
      <ul class="list-unstyled">
        <li>a. Aquellos Servicios que, por su naturaleza, hayan sido tramitados y no puedan ser devueltos.</li>
        <li>b. Aquellos supuestos en los que el Servicio haya sido completamente ejecutado.</li>
        <li>c. En el caso de que la ejecución del Servicio se haya iniciado con el previo consentimiento expreso del Cliente.</li>
      </ul>

      <h6>1.4.3 Reclamaciones</h6>
      <p>En el supuesto que el Técnico Audiovisual desee formalizar una reclamación podrá hacerlo dirigiéndose a AVIFY SOLUTIONS, S.L.U., Calle Juan Ramón Jiménez 7, 46900, Torrent, Valencia.
        , o por correo electrónico a <a href="mailto:hola@avify.net">hola@avify.net</a>
      </p>

      <h5>1.5 PEDIDOS Y CONDICIONES DE COMPRA DE LAS FORMACIONES</h5>
      <p>Los Técnicos Audiovisuales que deseen adquirir las formaciones descritos en la Cláusula 1.2.2 de los presentes TyCG deberán registrarse desde su Área de Usuario a través del formulario de inscripción, completando el formulario de registro con los datos que se soliciten, y seguir los pasos para la compra de la formación o formaciones en las que estén interesados.</p>
      <p>Las formaciones serán impartidas por profesionales en las materias objeto de la formación en cada caso.</p>

      <h6>1.5.1. Precios y forma de pago</h6>
      <p>Los precios de compra de las formaciones estarán detallados en la Plataforma se expresan en Euros e incluyen el IVA y los demás impuestos que pudieran corresponder.</p>
      <p>Los pagos podrán realizarse mediante PayPal, Stripe o a través de Tarjeta Crédito/Débito. La elección del medio de pago será del usuario.</p>
      <p>En caso de que el pago se haga efectivo a través del servicio externo titularidad de Paypal, estará sujeto a los Términos y Condiciones y a la Política de Privacidad de Paypal disponible en <a href="https://www.paypal.com" target="_blank">www.paypal.com.</a></p>
      <p>En caso de que el pago se haga efectivo a través del servicio externo titularidad de Stripe, estará sujeto a los Términos y Condiciones y a la Política de Privacidad de Stripe disponible en <a href="https://stripe.com/es" target="_blank">https://stripe.com/es.</a></p>

      <h6>1.5.2. Condiciones de acceso al material de apoyo</h6>
      <p>Las formaciones se impartirán a través de vídeos a los que los Técnicos Audiovisuales podrán acceder a través de su cuenta de usuario. El acceso a cada vídeo y material de apoyo no descargable proporcionado será limitado al tiempo por el cual el Técnico Audiovisual haya contratado la formación.</p>

      <h6>1.5.3. Derecho de desistimiento </h6>
      <p>Una vez que el usuario ha realizado el pago de la formación correspondiente, no procederá el desistimiento conforme al artículo 103 de la normativa de consumidores y usuarios, al considerarse que el servicio ha comenzado a prestarse y tener acceso inmediato a los contenidos de la formación (vídeos y otros materiales e información de apoyo). Por tanto, no será posible devolver al Técnico Audiovisual el importe desembolsado una vez efectuada la contratación.</p>

      <h6>1.6. DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</h6>
      <p>La Plataforma (sitio web y app) incluyendo a título enunciativo, pero no limitativo, su programación, edición, compilación y demás elementos necesarios para su funcionamiento, los diseños, logotipos, textos y/o gráficos son propiedad de <strong>AVIFY</strong>.</p>
      <p>Todos los derechos de propiedad intelectual e industrial de los Servicios ofrecidos a través de la Plataforma son propiedad exclusiva de <strong>AVIFY</strong>, o bien de terceros que han autorizado a <strong>AVIFY</strong> para la utilización de los mismos en su Sitio Web, correspondiéndoles el ejercicio exclusivo de los derechos de explotación de los mismos.</p>
      <p>Cualquier uso de esos contenidos no autorizado previamente por parte de <strong>AVIFY</strong> será considerado un incumplimiento grave de los derechos de propiedad intelectual o industrial y dará lugar a las responsabilidades legalmente establecidas.</p>

      <h5>1.7 RESPONSABILIDAD Y GARANTÍAS</h5>
      <p><strong>AVIFY</strong> no es responsable de la información, archivos o cualquier otro contenido que publiquen los Usuarios, ni valida los contenidos que los Usuarios o decidan remitir, por lo que <strong>AVIFY</strong> no será responsable, ni directa ni indirectamente, ni subsidiariamente, de los daños y perjuicios de cualquier naturaleza derivados de las comunicaciones realizadas o contenidos incluidos en la Plataforma.</p>
      <p>A título enunciativo <strong>AVIFY</strong> no se responsabiliza de:</p>
      <ul class="list-unstyled">
        <li>i. La satisfacción de las necesidades o exigencias personales del Técnico Audiovisual en relación con los recursos provistos por La Plataforma.</li>
        <li>ii. Los daños directos o indirectos y/o intangibles, incluyendo las lesiones personales sufridas como consecuencia de un mal uso de la Plataforma.</li>
        <li>iii. La pérdida de reputación, imagen, o datos, que pudiera ocurrir durante el uso de la Plataforma.</li>
        <li>iv. La falta de disponibilidad, mantenimiento y efectivo funcionamiento de la Plataforma, excluyéndose, en la máxima medida permitida por la legislación vigente, cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a la falta de disponibilidad o de continuidad del funcionamiento de la Plataforma.</li>
        <li>v. Cualquier defecto, error o problema de funcionamiento de la Plataforma.</li>
      </ul>
      <p><strong>AVIFY</strong> ha realizado los esfuerzos necesarios para que la información, documentación y comentarios incluidos en la Plataforma sean lo más exactos y correctos posible, y permitan ser de ayuda para los Usuarios, no obstante, deben ser considerados en todo caso por los Usuarios como orientativos.</p>
      <p>En consecuencia, <strong>AVIFY</strong> no garantiza ni se responsabiliza de la existencia de errores en dichos contenidos, ni se hace responsable, en ningún caso, de su uso por parte del Usuario o de la falta de utilidad de los mismos.</p>

      <h6>a. Suspensión y cancelación de los servicios</h6>
      <p><strong>AVIFY</strong> podrá suspender temporalmente y sin previo aviso, la accesibilidad a la Plataforma con motivo de operaciones de mantenimiento, reparación, actualización o mejora.</p>
      <p><strong>AVIFY</strong> no se responsabiliza de la falta de disponibilidad, mantenimiento y efectivo funcionamiento de la Plataforma excluyéndose, en la máxima medida permitida por la legislación vigente, cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a la falta de disponibilidad o de continuidad del funcionamiento de la Plataforma.</p>

      <h6>b. Fuerza mayor</h6>
      <p><strong>AVIFY</strong> no se responsabiliza de los casos de fuerza mayor, entendidos tales como el fallo, suspensión o interrupción de los servicios o utilización de la Plataforma, como consecuencia de bloqueo de la red de Internet, acciones u omisiones de terceras personas, o cualesquiera otras causas o circunstancias independientes de la voluntad de <strong>AVIFY</strong> que impidan la utilización normal de la Plataforma.</p>

      <h5>1.8 INDEMNIDAD</h5>
      <p>Los Usuarios mantendrán indemne, defenderán y exonerarán a <strong>AVIFY</strong> de todo daño, responsabilidad y coste que pudiera surgir como consecuencia de una reclamación de terceros contra <strong>AVIFY</strong>, y/o sus directivos y/o representante y/o empleado de cualquier cargo, acción o demanda, incluyendo, pero no limitándose a los gastos legales razonables, que resulten de cualquier material que ellos hayan proporcionado a <strong>AVIFY</strong> mediante cualquier uso indebido de los servicios que <strong>AVIFY</strong> provee o en violación de estos TyCG. <strong>AVIFY</strong> les notificará puntualmente de cualquier demanda, acción o proceso a través de los datos que ellos mismos hayan suministrado al momento del alta en los servicios que la Plataforma de <strong>AVIFY</strong> ofrece. Mediante la aceptación de los presentes TyCG los Usuarios reconocen y aceptan que <strong>AVIFY</strong> no tiene responsabilidad directa o indirecta en relación a los servicios contratados entre el Técnico Audiovisual y cada Empresa y/o con terceros.</p>

      <h5>1.9 DURACIÓN, SALVAGUARDIA Y MODIFICACIONES</h5>
      <p>Se entienden como vigentes, las condiciones que estén publicadas en el momento en el que el Técnico Audiovisual acceda a la Plataforma de <strong>AVIFY</strong>.</p>
      <p>Si alguna disposición de los presentes TyCG se declarase inaplicable o inválida, dicha disposición será limitada o eliminada en la medida mínima necesaria para que las restantes condiciones y obligaciones permanezcan en pleno vigor y efecto y de obligado cumplimiento.</p>
      <p><strong>AVIFY</strong> podrá suprimir, modificar o sustituir los contenidos del presente documento en cualquier momento, publicando o enviando una notificación a través de la Plataforma o por correo electrónico, sin posibilidad por parte del Técnico Audiovisual de exigir indemnización alguna.</p>

      <h5>1.10 IDIOMA</h5>
      <p><strong>AVIFY</strong> puede traducir los presentes Términos y Condiciones o cualquier otra política que pueda ser publicada en la Plataforma. La versión en español será la que prevalezca en caso de conflicto con otras traducciones. </p>

      <h5>1.11 RESOLUCIÓN DE CONTROVERSIAS – LEGISLACIÓN Y FUERO</h5>
      <p>Las partes se comprometen a pactar de buena fe su intención de resolver cualquier disputa en lo que respecta a los presentes TYCG. Los TYCG de <strong>AVIFY</strong> están sometidos a la legislación española. Las partes se someten a los Juzgados y Tribunales de Torrent (Valencia).</p>

      <h4>2. TÉRMINOS Y CONDICIONES PARA EMPRESAS DE “AVIFY “</h4>
      <h5>2.1 IDENTIFICACIÓN, ACEPTACIÓN Y ACCESO AL SITIO WEB</h5>
      <p>Los presentes Términos y Condiciones (en adelante, los “<strong>Términos y Condiciones</strong>” o los “<strong>TyCG</strong>”)  regulan, la descarga, acceso, navegación y uso de la aplicación móvil AVIFY y el Sitio Web <a href="https://avify.net/" target="_blank">https://avify.net/</a> (en adelante, denominadas conjuntamente, la “<strong>Plataforma</strong>”) titularidad de AVIFY SOLUTIONS, S.L.U. (en adelante, “<strong>AVIFY</strong>”), con CIF B-40653297 y domicilio social en la Calle Juan Ramón Jiménez 7, 46900, (Torrent) – Valencia, , inscrita en el Registro Mercantil de Valencia (Hoja V-192284, folio 195, tomo 10808).</p>
      <p>Toda persona que acceda o utilice el Sitio Web asume la condición de usuario, que supone la aceptación de estos Términos y Condiciones, así como de las diferentes modificaciones y/o textos legales adicionales que AVIFY pueda incluir en el futuro. El acceso y utilización de los contenidos y servicios tras la entrada en vigor de sus modificaciones o los cambios en las condiciones suponen la aceptación de las mismas.</p>

      <h5>2.2 SERVICIOS</h5>
      <p>A través de la Plataforma, AVIFY pone a disposición una serie de servicios dirigidos a empresas del sector audiovisual (en adelante, la “<strong>Empresa</strong>” o las “<strong>Empresas</strong>”) mediante los cuales ofrece a las Empresas la posibilidad de ponerse en contacto con técnicos audiovisuales (en adelante, “<strong>Técnicos Audiovisuales</strong>”) para la prestación de sus servicios en los eventos organizados por la Empresa (en adelante, el “<strong>Servicio</strong>”).</p>
      <p>Para poder acceder al Servicio ofrecido por AVIFY a través de la Plataforma, las Empresas deberán registrarse en la Plataforma y crear un perfil de Usuario mediante el cual podrán:</p>

      <p>a. <u>Encontrar al Técnico Audiovisual requerido</u>: La Empresa tendrá la posibilidad de contactar con los Técnicos Audiovisuales, a través del motor de búsqueda de la Plataforma, donde podrá filtrar según sus necesidades según:</p>
      <ol>
        <li>Las habilidades que la Empresa requiera.</li>
        <li>Disponibilidad de Fechas del Técnico Audiovisual.</li>
        <li>Área geográfica disponible para prestar el servicio por el Técnico Audiovisual.</li>
        <li>Otras condiciones especificadas por el Técnico Audiovisual.</li>
      </ol>
      <p>b. <u>Recibir presupuestos de Técnicos Audiovisuales</u>: Cuando una Empresa muestre interés en la contratación de un Técnico Audiovisual para la prestación de sus servicios en algún evento, el Técnico Audiovisual podrá enviar su presupuesto a la Empresa a través de la Plataforma.</p>
      <p>El envío de un presupuesto a través de la Plataforma de AVIFY, permitirá a los Técnicos Audiovisuales dejar constancia por escrito de los términos económicos pactados. Una vez celebrado el evento y prestado el servicio presupuestado por el Técnico Audiovisual, se emitirá una factura en función del presupuesto previamente creado a través de la Plataforma.</p>
      <p>En todo caso, la intervención y/o intermediación de AVIFY a través de la Plataforma se limita a la puesta en contacto de Técnicos Audiovisuales y Empresas y no crea ningún contrato de sociedad, de mandato, de franquicia, o relación laboral entre AVIFY y los usuarios. AVIFY no es parte en ninguna operación de contratación entre los Técnicos Audiovisuales y las Empresas, ni tiene control alguno sobre la calidad, seguridad o legalidad de los servicios requeridos o la veracidad o exactitud de los perfiles creados.</p>

      <h5>2.3 NECESIDAD DE REGISTRO</h5>
      <p>Para efectuar el registro en la Plataforma, la Empresa deberá crearse una cuenta, facilitando el nombre de la empresa, persona de contacto, teléfono, correo electrónico, seleccionar un nombre de usuario y escoger una contraseña de acceso a la Plataforma.</p>
      <p>El registro como usuarios se podrá hacer desde la propia App de AVIFY, así como desde la página web de AVIFY. Los usuarios garantizan que sus datos serán exactos, actuales y veraces.</p>
      <p>La Empresa podrá registrarse en la Plataforma a través de las siguientes formas:</p>

      <h6>A. Registro a través de Facebook</h6>
      <p>La Empresa podrá registrarse a través de su perfil de Facebook, introduciendo su usuario y contraseña de Facebook y obtener una cuenta en la Plataforma.</p>
      <p>Mediante el registro a través de Facebook, el Usuario autoriza a que AVIFY pueda conocer su perfil público y dirección de correo electrónico.</p>

      <h6>B. Registro a través de Google </h6>
      <p>La Empresa podrá registrarse a través de sus credenciales de Google, autorizando a que AVIFY pueda conocer su perfil público y dirección de correo electrónico.</p>

      <h6>C. Registro a través de AppleId.</h6>
      <p>La Empresa podrá registrarse a través de sus credenciales de Apple Id, autorizando a que AVIFY pueda conocer su perfil público y dirección de correo electrónico.</p>

      <h6>2.3.1 Custodia de códigos o contraseñas de acceso a la cuenta</h6>
      <p>La Empresa registrada será responsable en todo momento de la custodia de su contraseña de acceso a su cuenta de Usuario asumiendo en consecuencia cualesquiera daños y perjuicios que pudieran derivarse de su uso indebido, así como de la cesión, revelación o extravío de la misma, debiendo informar inmediatamente a AVIFY en caso de que tenga motivos para creer que su código de acceso o contraseña ha sido utilizado de manera no autorizada o es susceptible de serlo.</p>
      <p>En cualquier caso, el acceso y/o uso de la Plataforma realizado bajo el código de acceso o contraseña de la Empresa registrada se reputarán realizados por dicho Usuario, quien responderá en todo caso de dicho acceso y uso.</p>

      <h6>2.3.2 Perfil de la Empresa</h6>
      <p>Una vez que la Empresa haya creado una cuenta de acceso a la Plataforma tendrá acceso a un perfil donde podrá completar sus datos personales y/o los de la Empresa que forma parte, conforme a lo establecido en la Plataforma.</p>
      <p>AVIFY tiene el derecho a solicitar algún comprobante y/o dato adicional a efectos de corroborar los Datos Personales, así como de suspender temporal o definitivamente aquellos usuarios cuyos datos no han podido ser confirmados. En los casos de inhabilitación, se dará de baja al usuario de la Plataforma, sin que ello genere derecho a resarcimiento alguno.</p>
      <p>AVIFY tiene derecho a rechazar cualquier solicitud de registro o cancelar un registro previamente aceptado, sin que esté obligada a comunicar o exponer las razones de su decisión y sin que ello genere derecho a indemnización y/o resarcimiento alguno.</p>

      <h5>2.4 DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</h5>
      <p>La Plataforma (sitio web y app) incluyendo a título enunciativo, pero no limitativo, su programación, edición, compilación y demás elementos necesarios para su funcionamiento, los diseños, logotipos, textos y/o gráficos son propiedad de AVIFY.</p>
      <p>Todos los derechos de propiedad intelectual e industrial de los Servicios ofrecidos a través de la Plataforma son propiedad exclusiva de AVIFY, o bien de terceros que han autorizado a AVIFY para la utilización de los mismos en su Sitio Web, correspondiéndoles el ejercicio exclusivo de los derechos de explotación de los mismos.</p>
      <p>Cualquier uso de esos contenidos no autorizado previamente por parte de AVIFY será considerado un incumplimiento grave de los derechos de propiedad intelectual o industrial y dará lugar a las responsabilidades legalmente establecidas.</p>

      <h5>2.5 RESPONSABILIDAD Y GARANTÍAS</h5>
      <p>AVIFY no es responsable de la información, archivos o cualquier otro contenido que publiquen los Usuarios, ni valida los contenidos que los Usuarios o decidan remitir, por lo que AVIFY no será responsable, ni directa ni indirectamente, ni subsidiariamente, de los daños y perjuicios de cualquier naturaleza derivados de las comunicaciones realizadas o contenidos incluidos en la Plataforma.</p>
      <p>A título enunciativo AVIFY no se responsabiliza de:</p>
      <ul class="list-unstyled">
        <li>i. La satisfacción de las necesidades o exigencias personales de los Usuarios en relación con los recursos provistos por La Plataforma.</li>
        <li>ii. Los daños directos o indirectos y/o intangibles, incluyendo las lesiones personales sufridas como consecuencia de un mal uso de la Plataforma.</li>
        <li>iii. La pérdida de reputación, imagen, o datos, que pudiera ocurrir durante el uso de la Plataforma.</li>
        <li>iv. La falta de disponibilidad, mantenimiento y efectivo funcionamiento de la Plataforma, excluyéndose, en la máxima medida permitida por la legislación vigente, cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a la falta de disponibilidad o de continuidad del funcionamiento de la Plataforma.</li>
        <li>v. Cualquier defecto, error o problema de funcionamiento de la Plataforma.</li>
      </ul>
      <p>AVIFY ha realizado los esfuerzos necesarios para que la información, documentación y comentarios incluidos en la Plataforma sean lo más exactos y correctos posible, y permitan ser de ayuda para los Usuarios, no obstante, deben ser considerados en todo caso por los Usuarios como orientativos.</p>
      <p>En consecuencia, AVIFY no garantiza ni se responsabiliza de la existencia de errores en dichos contenidos, ni se hace responsable, en ningún caso, de su uso por parte del Usuario o de la falta de utilidad de los mismos.</p>

      <h6>2.5.1 Suspensión y cancelación de los servicios</h6>
      <p>AVIFY podrá suspender temporalmente y sin previo aviso, la accesibilidad a la Plataforma con motivo de operaciones de mantenimiento, reparación, actualización o mejora.</p>
      <p>AVIFY no se responsabiliza de la falta de disponibilidad, mantenimiento y efectivo funcionamiento de la Plataforma excluyéndose, en la máxima medida permitida por la legislación vigente, cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a la falta de disponibilidad o de continuidad del funcionamiento de la Plataforma, así como también de la efectiva prestación del servicio por parte del Técnico Audiovisual.</p>

      <h6>2.5.2 Fuerza mayor</h6>
      <p>AVIFY no se responsabiliza de los casos de fuerza mayor, entendidos tales como el fallo, suspensión o interrupción de los servicios o utilización de la Plataforma, como consecuencia de bloqueo de la red de Internet, acciones u omisiones de terceras personas, o cualesquiera otras causas o circunstancias independientes de la voluntad de AVIFY que impidan la utilización normal de la Plataforma.</p>

      <h5>2.6 INDEMNIDAD</h5>
      <p>Los Usuarios mantendrán indemne, defenderán y exonerarán a AVIFY de todo daño, responsabilidad y coste que pudiera surgir como consecuencia de una reclamación de terceros contra AVIFY, y/o sus directivos y/o representante y/o empleado de cualquier cargo, acción o demanda, incluyendo, pero no limitándose a los gastos legales razonables, que resulten de cualquier material que ellos hayan proporcionado a AVIFY mediante cualquier uso indebido de los servicios que AVIFY provee o en violación de estos TyCG. AVIFY les notificará puntualmente de cualquier demanda, acción o proceso a través de los datos que ellos mismos hayan suministrado al momento del alta en los servicios que la Plataforma de AVIFY ofrece. Mediante la aceptación de los presentes TyCG los Usuarios reconocen y aceptan que AVIFY no tiene responsabilidad directa o indirecta en relación a los servicios contratados entre el Técnico Audiovisual y cada Empresa y/o con terceros.</p>

      <h5>2.7 DURACIÓN, SALVAGUARDIA Y MODIFICACIONES</h5>
      <p>Se entienden como vigentes, las condiciones que estén publicadas en el momento en el que la Empresa acceda a la Plataforma de AVIFY.</p>
      <p>Si alguna disposición de los presentes TyCG se declarase inaplicable o inválida, dicha disposición será limitada o eliminada en la medida mínima necesaria para que las restantes condiciones y obligaciones permanezcan en pleno vigor y efecto y de obligado cumplimiento.</p>
      <p>AVIFY podrá suprimir, modificar o sustituir los contenidos del presente documento en cualquier momento, publicando o enviando una notificación a través de la Plataforma o por correo electrónico, sin posibilidad por parte de la Empresa de exigir indemnización alguna.</p>

      <h5>2.8 IDIOMA</h5>
      <p>AVIFY puede traducir los presentes Términos y Condiciones o cualquier otra política que pueda ser publicada en la Plataforma. La versión en español será la que prevalezca en caso de conflicto con otras traducciones. </p>

      <h5>2.9 RESOLUCIÓN DE CONFLICTOS</h5>
      <p>Para la resolución de cualquier discrepancia, cuestión o reclamación derivada directa o indirectamente de la interpretación o ejecución de los presentes TyCG, las partes renuncian al fuero propio que pueda corresponderles y se someten a la jurisdicción de los Juzgados y Tribunales de Torrent (Valencia).</p>

      <hr />
      <b-button @click="onAccept" block type="submit" variant="primary" ref="submit-button">
        Aceptar
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'TermsOfServiceModal',
  methods: {
    onAccept () {
      this.lockScreen()
      this.$store.dispatch('acceptTermsOfService')
        .then(() => {
          this.$router.push({ name: 'dashboard' })
        })
    }
  },
  data () {
    return {
      modalShow: true
    }
  }
}
</script>

<style lang="less">
  #modal-tos {
    .modal-title {
      text-align: center;
      width: 100%;
    }

    .modal-body {
      position: relative;
      flex: 1 1 auto;
      padding: 3rem;

      h4 {
        margin-bottom: 2rem;
      }

      p {
        font-size: 14px;
      }
    }
  }
</style>
